"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connector_details = {
    back_to_connectors: 'Zurück zu Connectoren',
    check_readme: 'Zur README',
    save_error_empty_config: 'Bitte fülle die Konfiguration aus',
    send: 'Senden',
    send_error_invalid_format: 'Ungültige Eingabe',
    edit_config_label: 'Gib deine JSON-Konfiguration ein',
    test_email_sender: 'Teste den E-Mail Connector',
    test_sms_sender: 'Teste den SMS Connector',
    test_email_placeholder: 'Gib eine Test-E-Mail ein',
    test_sms_placeholder: 'Gib eine Test-Telefonnummer ein',
    test_message_sent: 'Testnachricht wurde gesendet!',
    test_sender_description: 'Wenn dein JSON richtig konfiguriert ist, erhältst du eine Nachricht.',
    options_change_email: 'E-Mail Connector bearbeiten',
    options_change_sms: 'SMS Connector bearbeiten',
    connector_deleted: 'Der Connector wurde erfolgreich gelöscht',
    type_email: 'E-Mail connector',
    type_sms: 'SMS connector',
    type_social: 'Social connector',
};
exports.default = connector_details;
