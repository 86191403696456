"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_resource_details = {
    back_to_api_resources: 'Zurück zu API Ressourcen',
    token_expiration_time_in_seconds: 'Token Ablaufzeit (in Sekunden)',
    token_expiration_time_in_seconds_placeholder: 'Gib die Ablaufzeit des Tokens ein',
    delete_description: 'Diese Aktion kann nicht rückgängig gemacht werden. Die API Ressource wird permanent gelöscht. Bitte gib den API Ressourcennamen <span>{{name}}</span> zur Bestätigung ein.',
    enter_your_api_resource_name: 'Gib einen API Ressourcennamen ein',
    api_resource_deleted: 'Die API Ressource {{name}} wurde erfolgreich gelöscht',
};
exports.default = api_resource_details;
