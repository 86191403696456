"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const connectors = {
    title: 'Connectoren',
    subtitle: 'Richte Connectoren ein um passwortlose und Social Anmeldung zu aktivieren',
    create: 'Social Connector erstellen',
    tab_email_sms: 'E-Mail und SMS Connectoren',
    tab_social: 'Social Connectoren',
    connector_name: 'Connectorname',
    connector_type: 'Typ',
    connector_status: 'Anmeldeoberfläche',
    connector_status_in_use: 'In Benutzung',
    connector_status_not_in_use: 'Nicht in Benutzung',
    social_connector_eg: 'z.B. Google, Facebook, Github',
    save_and_done: 'Speichern und fertigstellen',
    type: {
        email: 'E-Mail Connector',
        sms: 'SMS Connector',
        social: 'Social Connector',
    },
    setup_title: {
        email: 'E-Mail Connector einrichten',
        sms: 'SMS Connector einrichten',
        social: 'Social Connector erstellen',
    },
    guide: {
        subtitle: 'Eine Schritt-für-Schritt-Anleitung zur Konfiguration deines Connectors',
    },
    platform: {
        universal: 'Universal',
        web: 'Web',
        native: 'Nativ',
    },
    add_multi_platform: ' unterstützt mehrere Plattformen, wähle eine Plattform aus, um fortzufahren',
    drawer_title: 'Connector Anleitung',
    drawer_subtitle: 'Folge den Anweisungen, um deinen Connector zu integrieren',
};
exports.default = connectors;
