"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const users = {
    title: 'Benutzerverwaltung',
    subtitle: 'Verwalten von Benutzeridentitäten, einschließlich des Anlegens von Benutzern, Bearbeiten von Benutzerinformationen, Anzeigen von Benutzer-Logs, Zurücksetzen von Passwörtern und Löschen von Benutzern',
    create: 'Benutzer hinzufügen',
    user_name: 'Benutzer',
    application_name: 'Anwendungsname',
    latest_sign_in: 'Letzte Anmeldung',
    create_form_username: 'Benutzername',
    create_form_password: 'Passwort',
    create_form_name: 'Name',
    unnamed: 'Unbenannt',
};
exports.default = users;
