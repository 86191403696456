"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const general = {
    placeholder: 'Platzhalter',
    skip: 'Überspringen',
    next: 'Weiter',
    retry: 'Erneut versuchen',
    done: 'Fertig',
    search: 'Suche',
    search_placeholder: 'Suchen',
    clear_result: 'Ergebnisse löschen',
    save: 'Speichern',
    save_changes: 'Änderungen speichern',
    saved: 'Gespeichert!',
    loading: 'Lade...',
    redirecting: 'Weiterleiten...',
    add: 'Hinzufügen',
    added: 'Hinzugefügt',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    check_out: 'Ansehen',
    create: 'Erstellen',
    set_up: 'Einrichten',
    customize: 'Anpassen',
    enable: 'Aktivieren',
    reminder: 'Erinnerung',
    delete: 'Löschen',
    more_options: 'MEHR OPTIONEN',
    close: 'Schließen',
    copy: 'Kopieren',
    copying: 'Kopiere',
    copied: 'Kopiert',
    required: 'Erforderlich',
    add_another: '+ Weitere hinzufügen',
    deletion_confirmation: 'Willst du {{title}} wirklich löschen?',
    settings_nav: 'Einstellungen',
    unsaved_changes_warning: 'Du hast ungespeicherte Änderungen. Willst du diese Seite wirklich verlassen?',
    leave_page: 'Seite verlassen',
    stay_on_page: 'Auf Seite bleiben',
    type_to_search: 'Tippe um zu suchen',
};
exports.default = general;
